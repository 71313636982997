import { NavLink } from 'react-router-dom';
import css from './Sidebar.module.css';

function Sidebar() {
  return (
    <div className={css.menu}>
      <NavLink to="/">
        <div className={css.link}>Dashboard</div>
      </NavLink>
      <div className={css.groupHeader}>
        <span>Sales</span>
        <NavLink to="/customer">
          <div className={css.link}>Customers</div>
        </NavLink>
        <NavLink to="/contract">
          <div className={css.link}>Contracts</div>
        </NavLink>
      </div>
      <div className={css.groupHeader}>
        <span>Training</span>
        <NavLink to="/student">
          <div className={css.link}>Students</div>
        </NavLink>
        <NavLink to="/course">
          <div className={css.link}>Courses</div>
        </NavLink>
      </div>
      <div className={css.groupHeader}>
        <span>Quality</span>
        <NavLink to="/audit">
          <div className={css.link}>Audits</div>
        </NavLink>
        <NavLink to="/event">
          <div className={css.link}>Events</div>
        </NavLink>
      </div>
    </div>
  );
}

export default Sidebar;
