import * as request from './utils/requester';

export const getAll = (page) => request.get(`/course?page=${page}`);

export const getById = (id) => request.get(`/course/${id}`);

export const create = (data) => request.post('/course', data);

export const edit = (data) => request.put(`/course/${data._id}`, data);

export const del = (data) => request.del(`/course/${data._id}`);