import React, { useState } from 'react';
import css from './AddNew.module.css';

function AddNew (data, onAdd, service) {


    const [showModal, setShowModal] = useState(false);

  const headers = Object.keys(data[0] || {});
  //   const headers = ['one','two'];
  // console.log(data);
  let template = {};
  headers.forEach(x => (template[x] = ''));

  const [newData, setNewData] = useState(template);

  const handleChange = e => {
    const { name, value } = e.target;
    setNewData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    // Send the data to the backend and wait for a response
    service
      .create(newData)
      .then(result => {
          onAdd(newData); // Update parent component's state
        
        toggleModal(); // Close modal after submitting
        setNewData(prevDate => template);
        // console.log(result);
      })
      .catch(err => {
        console.log(err);
      });
    // try {
    //   await customerServices (newData);
    //   setNewData({ name: '', age: '', job: '' }); // Reset form
    // } catch (error) {
    //   console.error('Failed to add data:', error);
    // }
  };

  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <button onClick={toggleModal} className={css.button}>
        +
      </button>
      {showModal && (
        <div className={css.backdrop}>
          <div className={css.modal}>
            <form onSubmit={handleSubmit} className={css.form}>
              {headers.map((x, i) => (
                <input
                  key={i}
                  type="text"
                  name={x}
                  placeholder={`Enter ${x}: `}
                  value={newData[x]}
                  onChange={handleChange}
                  className={css.input}
                />
              ))}

              <button type="submit" className={css.button}>
                Add Data
              </button>
              <button onClick={toggleModal} className={css.button}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default AddNew;