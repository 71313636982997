const toShort = (longDate, option) => {
  if (longDate) {
    // console.log(longDate);
    let shortDate = longDate ? new Date(longDate) : undefined;
    let options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    };
    if (option) {
      if (option === 1) {
        options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        };
        return shortDate
          .toLocaleString('ko-KR', options)
          .replace(/. /g, '-')
          .replace(/\uC77C/g, '')
          .replace(/\./, '');
      }
    } else {
      return shortDate.toLocaleString('en-GB', options).replace(/ /g, '-');
    }
    // return shortDate.toLocaleString('en-GB', options).replace(/ /g, ' ');
  } else {
    return 'n/a';
  }
};

// const dateParser = { toShort };
export default toShort;
