const courseTypeOptions = [
  {
    courseTypeId: 0,
    courseTypeName: '... select',
  },
  {
    courseTypeId: 1,
    courseTypeName: 'Theoretical Type Training',
  },
  {
    courseTypeId: 2,
    courseTypeName: 'Practical Type Training',
  },
  {
    courseTypeId: 3,
    courseTypeName: 'GenFam (Cat C)',
  },
  {
    courseTypeId: 4,
    courseTypeName: 'GenFam (Attendance)',
  },
  {
    courseTypeId: 5,
    courseTypeName: 'Skills Training',
  },
];
const courseStatusOptions = [
  {
    courseStatusId: 0,
    courseStatusName: '... select',
  },
  {
    courseStatusId: 1,
    courseStatusName: 'Planned',
  },
  {
    courseStatusId: 2,
    courseStatusName: 'In Progress',
  },
  {
    courseStatusId: 3,
    courseStatusName: 'Closed',
  },
  {
    courseStatusId: 4,
    courseStatusName: 'Cancelled',
  },
];

const data = { courseTypeOptions, courseStatusOptions };
export default data;
