import * as request from './utils/requester';

export const getAll = (page) => request.get(`/student?page=${page}`);

export const getById = (id) => request.get(`/student/${id}`);

export const create = (data) => request.post('/student', data);

export const edit = (data) => request.put(`/student/${data._id}`, data);

export const del = (data) => request.del(`/student/${data._id}`);