import { useState, useEffect } from 'react';
import css from './Contract.module.css';

import * as contractServices from '../../../../services/contractService';
import Table from '../../_common/data/Table';
// import CourseList from './CourseList';
// import CourseOptions from './CourseOptions';

function Contract() {
  const [contracts, setContract] = useState([]);

  useEffect(() => {
    let page;
    contractServices
      .getAll(page || 1)
      .then(result => {
        // console.log(result);
        setContract(state => result.data || []);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const onAdd = newData => {
    setContract(state => [...state, newData]);
  };

  return (
    <div className={css.main}>
      <div>
        {/* <AddContract data={contracts} /> */}
        <Table data={contracts} onAdd={onAdd} service={contractServices} />
      </div>
      <div></div>
    </div>
  );
}

export default Contract;
