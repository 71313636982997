import css from './CourseOptions.module.css';
import data from './../../../data/tempDb'

function CourseOptions({ addCourse, clearForm, formChange, newCourse }) {
  const courseTypeOptions = data.courseTypeOptions;
  const courseStatusOptions = data.courseStatusOptions;

  return (
    <div className={css.options}>
      <h2>Options</h2>
      <p>Add course</p>
      <label>
        Course ID:
        <input
          type="text"
          value={newCourse.courseNo}
          onChange={e => formChange(e, 'courseNo')}
        />
      </label>
      <label>
        Course title:
        <input
          type="text"
          value={newCourse.courseTitle}
          onChange={e => formChange(e, 'courseTitle')}
        />
      </label>
      <label>
        Course type:
        <select
          value={newCourse.courseTypeId}
          onChange={e => formChange(e, 'courseTypeId')}
        >
          {courseTypeOptions.map(x => (
            <option value={x.courseTypeId}>{x.courseTypeName}</option>
          ))}
        </select>
      </label>
      <label>
        Start:
        <input
          type="date"
          value={newCourse.courseStart}
          onChange={e => formChange(e, 'courseStart')}
        />
      </label>
      <label>
        End:
        <input
          type="date"
          value={newCourse.courseEnd}
          onChange={e => formChange(e, 'courseEnd')}
        />
      </label>
      <label>
        Course status:
        <select
          value={newCourse.courseStatusId}
          onChange={e => formChange(e, 'courseStatusId')}
        >
          {courseStatusOptions.map(x => (
            <option value={x.courseStatusId}>{x.courseStatusName}</option>
          ))}
        </select>
      </label>
      <div>
        <button onClick={e => addCourse(e)}>Add</button>
        <button onClick={e => clearForm(e)}>Clear</button>
      </div>
    </div>
  );
}

export default CourseOptions;
