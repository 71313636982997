import * as request from './utils/requester';

export const getAll = (page) => request.get(`/customer?page=${page}`);

export const getById = (id) => request.get(`/customer/${id}`);

export const create = (data) => request.post('/customer', data);

export const edit = (data) => request.put(`/customer/${data._id}`, data);

export const del = (data) => request.del(`/customer/${data._id}`);