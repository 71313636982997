import React, { useState, useMemo } from 'react';
import css from './Table.module.css';
import AddNew from './AddNew';
import componentFunctions from '../../../../services/tools/componentFunctions';

function Table({ onAdd, data }) {
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState(componentFunctions.sortConfig);
  const [isError, setIsError] = useState(false);
  const requestSort = key =>
    componentFunctions.requestSort(key, sortConfig, setSortConfig);

  const sortedAndFilteredData = useMemo(() => {
    return componentFunctions.sortedAndFilteredData (data, sortConfig, filter, isError, setIsError);
  }, [data, sortConfig, filter]);

  if (!data || !data.length) {
    return <p>No data available.</p>;
  }

  const headers = Object.keys(data[0]);

  return (
    <>
      <div className={css.control}>
        <input
          type="text"
          placeholder="Filter results..."
          value={filter}
          onChange={e => setFilter(e.target.value)}
          className={css.filterInput}
          //box-shadow: 0 0 8px 0 #54b5f7;
          // style={{color: isError ? "red" : "black"}}
          style={{
            boxShadow: isError ? '0 0 8px 0 #ff0000' : '0 0 8px 0 #54b5f7',
          }}
        />
        <AddNew data={data} onAdd={onAdd} />
      </div>

      <table className={css.table}>
        <thead className={css.thead}>
          <tr>
            {headers.map(header => (
              <th
                key={header}
                onClick={() => requestSort(header)}
                className={css.th}
                style={{ cursor: 'pointer' }}
              >
                {header}
                {sortConfig.key === header
                  ? sortConfig.direction === 'ascending'
                    ? ' 🔼'
                    : ' 🔽'
                  : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={css.tbody}>
          {sortedAndFilteredData.length > 0 ? (
            sortedAndFilteredData.map((item, index) => (
              <tr key={index}>
                {headers.map((header, headerIndex) => (
                  <td key={headerIndex} className={css.td}>
                    {item[header]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={headers.length} style={{ textAlign: 'center' }}>
                No matching records found. Please adjust your filter.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default Table;
