import { useState } from 'react';
import toShort from '../../../services/utils/dateParser';
import css from './CourseList.module.css';

function CourseList({ courses, filter, editHandler, editId }) {
  console.log(editId);
  // const [courseList, setCourseList] = useState([...courses])

  // const filter =(e) => {
  //   console.log(courseList.length);

  //   // let temp = courseList.sort((a,b) => a[e.target.id].localeCompare(b[e.target.id]));
  //   // console.log(courseList[0].courseTitle);

  //   setCourseList(state => state.sort((a,b) => a[e.target.id].localeCompare(b[e.target.id])))
  // }

  return (
    <div className={css.left}>
      <h2>Courses List</h2>
      <table className={css.table}>
        <thead className={css.tableHeader}>
          <tr>
            <td id="courseNo" onClick={e => filter(e)}>
              ID
            </td>
            <td id="courseTitle" onClick={e => filter(e)}>
              Title
            </td>
            <td id="courseTypeId" onClick={e => filter(e)}>
              Type
            </td>
            <td id="courseStart" onClick={e => filter(e)}>
              Start
            </td>
            <td id="courseEnd" onClick={e => filter(e)}>
              End
            </td>
            <td id="courseStatusId" onClick={e => filter(e)}>
              Status
            </td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {courses.map(x => {
            if (Number(editId) !== Number(x.coursesId)) {
              return (
                <tr className={css.tableRow} key={x.coursesId || x.courseNo}>
                  <td>{x.courseNo}</td>
                  <td>{x.courseTitle}</td>
                  <td>{x.courseTypeId || '-'}</td>
                  <td>{toShort(x.courseStart) || '-'}</td>
                  <td>{toShort(x.courseEnd) || '-'}</td>
                  <td>{x.courseStatusId || '-'}</td>
                  <td>
                    <button id={x.coursesId} onClick={e => editHandler(e)}>
                      E
                    </button>
                    <button
                      id={x.coursesId}
                      onClick={e => console.log('Delete: ' + e.target.id)}
                    >
                      D
                    </button>
                  </td>
                </tr>
              );
            } else {
              console.log('false')
              return (
                <tr className={`${css.tableRow} ${css.red}`} key={x.coursesId || x.courseNo}>
                  <td><input className={css.rowInput} defaultValue={x.courseNo} type="text" /></td>
                  <td><input type="text" defaultValue={x.courseTitle}/></td>
                  <td><select type="text" defaultValue={x.courseType}>
                    <option value='1'>What</option>
                  </select></td>
                  <td><input type="date" defaultValue={toShort(x.courseStart, 1)}/></td>
                  <td><input type="date" defaultValue={toShort(x.courseEnd, 1)}/></td>
                  <td><select type="text" defaultValue={x.courseStatusId}/></td>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
      <div>
        <span>Page (NW): </span>
        <span> 1 </span>
        <span> 2 </span>
        <span> 3 </span>
      </div>
    </div>
  );
}

export default CourseList;
