import { useEffect, useState } from 'react';
import css from './Student.module.css';
import * as studentServices from './../../../services/studentServices';
import Table from '../_common/data/Table';

function Student() {
  const [students, setCustomer] = useState([]);

  useEffect(() => {
    let page;
    studentServices
      .getAll(page || 1)
      .then(result => {
        // console.log(result);
        setCustomer(state => result.data || []);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const onAdd = newData => {
    setCustomer(state => [...state, newData]);
  };

  return (
    <div className={css.main}>
      <div className={css.table}>
        {/* <AddCustomer data={customers} /> */}
        <Table data={students} onAdd={onAdd} service={studentServices} />
      </div>
      <div className={css.info}>

      </div>
    </div>
  );
}

export default Student;
