import { useState, useEffect } from 'react';
import css from './Customer.module.css';

import * as customerServices from '../../../../services/customerServices';
import Table from '../../_common/data/Table';
// import CourseList from './CourseList';
// import CourseOptions from './CourseOptions';

function Customer() {
  const [customers, setCustomer] = useState([]);

  useEffect(() => {
    let page;
    customerServices
      .getAll(page || 1)
      .then(result => {
        // console.log(result);
        setCustomer(state => result.data || []);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const onAdd = newData => {
    setCustomer(state => [...state, newData]);
  };

  return (
    <div className={css.main}>
      <div>
        {/* <AddCustomer data={customers} /> */}
        <Table data={customers} onAdd={onAdd} service={customerServices} />
      </div>
      <div></div>
    </div>
  );
}

export default Customer;
