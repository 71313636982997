import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useLocalStorage } from './hooks/useLocalStorage';

import css from './App.module.css';
import Sidebar from './components/02-sidebar/Sidebar';
import Dashboard from './components/main/dashboard/Dashboard';
import Student from './components/main/student/Student';
import Course from './components/main/course/Course';
import Header from './components/01_header/Header';
import Customer from './components/main/sales/customer/Customer';
import Contract from './components/main/sales/contract/Contract';
import Login from './components/main/_common/Login';

function App() {
  const [password, setPassword] = useLocalStorage('pass', '');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (!isAuthenticated) {
    return (
      <Login
        password={password}
        setPassword={setPassword}
        setIsAuthenticated={setIsAuthenticated}
      />
    );
  }

  const handleLogout = () => {
    setIsAuthenticated(false);
    setPassword('');
  };

  return (
    <div className={css.App}>
      <header className={css.Header}>
        <Header />
      </header>
      <BrowserRouter>
        <div className={css.Content}>
          <nav className={css.Sidebar}>
            <Sidebar />
          </nav>
          <main className={css.Main}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/student" element={<Student />} />
              <Route path="/course" element={<Course />} />
              <Route path="/customer" element={<Customer />} />
              <Route path="/contract" element={<Contract />} />
            </Routes>
          </main>
        </div>
      </BrowserRouter>
      <footer className={css.Footer}>
        <span onClick={() => handleLogout()}>2024@PCA v.0.1.1</span>
      </footer>
    </div>
  );
}

export default App;
