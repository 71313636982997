import * as request from './utils/requester';

export const getAll = (page) => request.get(`/contract?page=${page}`);

export const getById = (id) => request.get(`/contract/${id}`);

export const create = (data) => request.post('/contract', data);

export const edit = (data) => request.put(`/contract/${data._id}`, data);

export const del = (data) => request.del(`/contract/${data._id}`);