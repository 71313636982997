import css from './Header.module.css';

function Header() {
  return (
    <div className={css.header}>
      <div className={css.logo}>
        <img src="logo-white.png" alt="logo" />
      </div>
      <div className={css.title}>
        <span>Plane Care Academy</span>
      </div>
      <div className={css.info}>
        <span>crmdev@pc-acad.com</span>
      </div>
    </div>
  );
}

export default Header;
