//Lists
const requestSort = (key, sortConfig, setSortConfig) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    } else {
      direction = 'ascending';
    }
    setSortConfig({ key, direction });
  };

  const sortConfig = {
    key: null,
    direction: 'ascending',
  }

  const sortedAndFilteredData = (data, sortConfig, filter, isError, setIsError) => {
    let sortableItems = [...data];
    try {
      if (filter) {
        sortableItems = sortableItems.filter(item =>
          Object.keys(item).some(header =>
            item[header]?.toString().toLowerCase().includes(filter.toLowerCase())
          )
        );
      }
    } catch (error) {
      console.log(error);
      error ? (setIsError(st => true)) : (setIsError(st => false));
      console.log(isError);
    }
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }













  const componentFunctions = {sortConfig, requestSort, sortedAndFilteredData}

  export default componentFunctions;