import { useState } from 'react';
import css from './Login.module.css'

function Login({ password, setPassword, setIsAuthenticated }) {
  const [error, setError] = useState('');

  const correctPassword = '123'; // Set your password here

  const handleLogin = e => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('Incorrect password, please try again.');
    }
  };

  return (
    <div className={css.container}>
      <form onSubmit={handleLogin} className={css.form}>
        <div className={css.welcomeMessage}>
          <h2>Welcome</h2>
          <p>Please log in to continue</p>
        </div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
          className={css.input}
          required
        />
        <button type="submit" className={css.button}>Login</button>
        {error && <p className={css.error}>{error}</p>}
      </form>
    </div>
  );
}

export default Login;
