import { useState, useEffect } from 'react';
import css from './Course.module.css';

import * as courseServices from '../../../services/courseServices';
import CourseList from './CourseList';
import CourseOptions from './CourseOptions';

function Course() {
  const [courses, setCourse] = useState([]);
  const [newCourse, setNewCourse] = useState({
    courseNo: '',
    courseTitle: '',
    courseTypeId: '',
    courseStart: '',
    courseEnd: '',
    courseStatusId: '',
  });
  const [editId, setEditId] = useState(0);

  //Test DB
  useEffect(() => {
    let page;
    courseServices
      .getAll(page || 1)
      .then(result => {
        // console.log(result);
        setCourse(state => result.data || []);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const addCourse = e => {
    e.preventDefault();
    console.log('Clicked');
    courseServices
      .create(newCourse)
      .then(result => {
        console.log(result);
        setCourse(state => [...state, newCourse]);
        clearForm();
      })
      .catch(err => {
        console.log(err);
      });
    // setCourse(state => {
    //   console.log(state);
    //   return [...state, newCourse];
    // });
    setNewCourse(state => {
      console.log(state);
      return { ...state, id: '' };
    });
  };

  const formChange = (e, prop) => {
    console.log(prop);
    setNewCourse(state => {
      console.log(state);
      return { ...state, [prop]: e.target.value };
    });
  };

  const clearForm = e => {
    setNewCourse(state => {
      return {
        courseNo: '',
        courseTitle: '',
        courseTypeId: '',
        courseStart: '',
        courseEnd: '',
        courseStatusId: '',
      };
    });
  };

  const editHandler = e => {
    console.log(e.target.id);
    setEditId(state => (state = e.target.id));
  };

  const [filterFlip, setFilterFlip] = useState(false);
  const filter = e => {
    setFilterFlip(state => !state);
    if (e.target.id === 'courseId' || e.target.id === 'courseTitle') {
      setCourse(state =>
        [...state].sort((a, b) =>
          filterFlip
            ? a[e.target.id].localeCompare(b[e.target.id])
            : b[e.target.id].localeCompare(a[e.target.id])
        )
      );
    } else if (e.target.id === 'courseStart' || e.target.id === 'courseEnd') {
      setCourse(state =>
        [...state].sort((a, b) =>
          filterFlip
            ? new Date(a[e.target.id]) - new Date(b[e.target.id])
            : new Date(b[e.target.id]) - new Date(a[e.target.id])
        )
      );
    } else {
      setCourse(state =>
        [...state].sort((a, b) => a[e.target.id] - b[e.target.id])
      );
    }
  };

  return (
    <div className={css.course}>
      <CourseList
        courses={courses}
        filter={filter}
        editHandler={editHandler}
        editId={editId}
      />
      <CourseOptions
        addCourse={addCourse}
        clearForm={clearForm}
        formChange={formChange}
        newCourse={newCourse}
      />
      
    </div>
  );
}

export default Course;
